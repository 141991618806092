import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The blueberry maggot degree day model is based on over ten years of
        monitoring fly emergence in Michigan, compiled by Rufus Isaacs, Carlos
        Garcia-Salazar, and John Wise, Michigan State University.
      </p>

      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA tool was built by 
        <a href="mailto:jec3@cornell.edu">Juliet Carroll</a> and {" "}
        <a href="mailto:gme1@cornell.edu">Greg Loeb</a>, Cornell University,
        with input from Dean Polk, Rutgers University, and Hannah Burrack, North
        Carolina State University.
      </p>
    </>
  )
}
