import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Isaacs, R., Garcia-Salazar, C. and Wise, J. 2020. Enviroweather
        Blueberry Maggot degree day model, available at
        https://enviroweather.msu.edu/.
      </p>

      <p className="text-sm leading-5 text-left text-gray-500">
        Teixeira, L.A.F and Polavarapu, S. 2001. Occurrence of late-emerging
        populations of the blueberry maggot fly (Diptera: Tephritidae). The
        Canadian Entomologist. 133 (2) pp 239-250.
      </p>
    </>
  )
}
