import React from "react";
import EnvirValuesTable from "../../components/models/envirValuesTable";
import ManagementGuide from "../../components/models/managementGuide";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./blueberry-maggot.json";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsGraph from "./_resultsGraph";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function BlueberryMaggot() {
  const h1Text = modelData.title;
  const { seasonEnd } = modelData;
  const { managementGuide, resultsTable2 } = modelData.elements;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(data, dateOfInterest, modelData, isModelInSeason);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showResultsGraph, setShowResultsGraph] = React.useState(true);
  const [showEnvTable, setShowEnvTable] = React.useState(true);
  const isVisible = station && mData && !isLoading;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-2xl lg:text-3xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsTable2={showEnvTable}
      setShowResultsTable2={setShowEnvTable}
      showResultsChart={showResultsGraph}
      setShowResultsChart={setShowResultsGraph}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
      s
    >
      {/* Management Guide */}
      {!isModelInSeason && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            {managementGuide.title}
          </h2>

          <ManagementGuide
            managementGuideMsg={managementGuide.outOfSeason}
            explanation={managementGuide.explanation}
            setIsMoreInfo={setIsMoreInfo}
          ></ManagementGuide>
        </div>
      )}

      {isVisible && showManagementGuide && isModelInSeason && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            {managementGuide.title}
          </h2>

          <ManagementGuide
            managementGuideMsg={mData.managementGuideMsg}
            explanation={managementGuide.explanation}
            setIsMoreInfo={setIsMoreInfo}
          ></ManagementGuide>
        </div>
      )}

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            tableData={mData.tableData}
            csvData={mData.csvData}
          ></ResultsTable>
        </div>
      )}

      {/* Results Graph */}
      {isModelInSeason && isVisible && showResultsGraph && (
        <div
          className="mb-16 sm:mb-18 md:mb-20"
          aria-label="A line Graph of Results Data"
        >
          <ResultsGraph data={mData.data}></ResultsGraph>
        </div>
      )}

      {/* Environmental Variables Table */}
      {isModelInSeason && isVisible && showEnvTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <EnvirValuesTable
            tableData={mData.tableData}
            csvData={mData.csvDataEnvTable}
            envirValuesTable={resultsTable2}
            modelName={modelData.title}
          ></EnvirValuesTable>
        </div>
      )}
    </ModelLayout>
  );
}
